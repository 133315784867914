/* eslint-disable */
import React from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const location = useLocation();
  const authReduxData = useSelector(state => state.auth);
  const { userData } = authReduxData;

  const isPathActive = path => {
    return location.pathname.startsWith(path);
  };

  // useEffect(() => {
  //   // this.onRouteChanged();
  //   // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
  //   const body = document.querySelector('body');
  //   document.querySelectorAll('.sidebar .nav-item').forEach(el => {
  //     el.addEventListener('mouseover', function () {
  //       if (body.classList.contains('sidebar-icon-only')) {
  //         el.classList.add('hover-open');
  //       }
  //     });
  //     el.addEventListener('mouseout', function () {
  //       if (body.classList.contains('sidebar-icon-only')) {
  //         el.classList.remove('hover-open');
  //       }
  //     });
  //     el.addEventListener('click', function () {
  //       console
  //       if (body.classList.contains('sidebar-icon-only')) {
  //         el.classList.remove('hover-open');
  //       }
  //     });
  //   });
  // });

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <a href="!#" className="nav-link" onClick={evt => evt.preventDefault()}>
            <div className="nav-profile-image">
              <img src={require('../../assets/images/faces/face1.jpg')} alt="profile" />
              <span className="login-status online"></span> {/* change to offline or busy as needed */}
            </div>
            <div className="nav-profile-text">
              <span className="font-weight-bold mb-2">
                <Trans>{userData.name.toUpperCase() || ''}</Trans>
              </span>
              <span className="text-secondary text-small">
                <Trans>{userData.user_role.name || ''}</Trans>
              </span>
            </div>
            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>
        <li className={isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/dashboard">
            <span className="menu-title">
              <Trans>Dashboard</Trans>
            </span>
            <i className="mdi mdi-home menu-icon"></i>
          </Link>
        </li>
        <li className={isPathActive('/bills') ? 'nav-item active' : 'nav-item'}>
          <div className={'nav-link'} onClick={() => this.toggleMenuState('billManagementMenu')} data-toggle="collapse">
            <span className="menu-title">
              <Trans>Bill Management</Trans>
            </span>
            <i className="mdi mdi-contrast-circle menu-icon"></i>
          </div>
          <Collapse in={true}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className={isPathActive('/bills/list') ? 'nav-link active' : 'nav-link'} to="/bills/list">
                  <Trans>List All Bills</Trans>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/bills/wholesale/create') ? 'nav-link active' : 'nav-link'}
                  to="/bills/wholesale/create"
                >
                  <Trans>Create Whole Sale Bill</Trans>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={isPathActive('/bills/retail/create') ? 'nav-link active' : 'nav-link'}
                  to="/bills/retail/create"
                >
                  <Trans>Create Retail Bill</Trans>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className={isPathActive('/bills/gst') ? 'nav-link active' : 'nav-link'} to="/bills/gst">
                  <Trans>Create GST Bill</Trans>
                </Link>
              </li> */}
            </ul>
          </Collapse>
        </li>
        <li className={isPathActive('/transactions') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/transactions">
            <span className="menu-title">
              <Trans>Transaction Management</Trans>
            </span>
            <i className="mdi mdi-coin menu-icon"></i>
          </Link>
        </li>
        <li className={'nav-item'}>
          <div className={'nav-link'} onClick={() => this.toggleMenuState('statementMenu')} data-toggle="collapse">
            <span className="menu-title">
              <Trans>Statements</Trans>
            </span>
            <i className="mdi mdi-contrast-circle menu-icon"></i>
          </div>
          <Collapse in={true}>
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link
                  className={isPathActive('/account-statement') ? 'nav-link active' : 'nav-link'}
                  to="/account-statement"
                >
                  <Trans>Account Statement</Trans>
                </Link>
              </li>
              <li className="nav-item">
                <Link className={isPathActive('/cash-book') ? 'nav-link active' : 'nav-link'} to="/cash-book">
                  <Trans>Cash Book</Trans>
                </Link>
              </li>
            </ul>
          </Collapse>
        </li>
        {/* <li className={isPathActive('/attendance') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/attendance">
            <span className="menu-title">
              <Trans>Attendance Management</Trans>
            </span>
            <i className="mdi mdi-calendar-clock menu-icon"></i>
          </Link>
        </li> */}
        <li className={isPathActive('/users') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/users">
            <span className="menu-title">
              <Trans>User Management</Trans>
            </span>
            <i className="mdi mdi-account-multiple menu-icon"></i>
          </Link>
        </li>
        <li className={isPathActive('/company') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/company">
            <span className="menu-title">
              <Trans>Company Management</Trans>
            </span>
            <i className="mdi mdi-city menu-icon"></i>
          </Link>
        </li>
        <li className={isPathActive('/items') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/items">
            <span className="menu-title">
              <Trans>Item Management</Trans>
            </span>
            <i className="mdi mdi-buffer menu-icon"></i>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default withRouter(Sidebar);
