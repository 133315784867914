import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import facePng from '../../assets/images/faces/face1.jpg';
import useAuthUserData from '../../hooks/useAuthUserData';
import { LOGOUT } from '../../redux/reducers/authReducer';
import { clearAppLanguage } from '../../redux/reducers/languageReducers';
import { clearSelectedCompany } from '../../redux/reducers/selectedCompanyReducers';

const Navbar = () => {
  const dispatch = useDispatch();
  const { userData, selectedCompany } = useAuthUserData();
  const toggleOffcanvas = () => {
    // eslint-disable-next-line no-undef
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  };

  const handleLogoutClick = () => {
    dispatch(clearAppLanguage());
    dispatch(clearSelectedCompany());
    dispatch(LOGOUT());
  };

  const getCompanyShortForm = () => {
    if (selectedCompany.label) {
      const result = selectedCompany.label
        .split(' ')
        .map(word => word[0].toUpperCase())
        .join('');

      return result;
    }

    return 'CTM';
  };

  const handleChangeCompanyClick = () => {
    dispatch(clearSelectedCompany());
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/">
          {selectedCompany.label || 'Chamat Timber Mart'}
        </Link>
        <Link className="navbar-brand brand-logo-mini" to="/">
          {getCompanyShortForm()}
        </Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => {
            // eslint-disable-next-line no-undef
            return document.body.classList.toggle('sidebar-icon-only');
          }}
        >
          <span className="mdi mdi-menu" />
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link">
                <div className="nav-profile-img">
                  <img src={facePng} alt="user" />
                  <span className="availability-status online" />
                </div>
                <div className="nav-profile-text">
                  <p className="mb-1 text-black">{userData.name || ''}</p>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="mdi mdi-account mr-2 text-primary" />
                  <Trans>My Profile</Trans>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={() => handleChangeCompanyClick()}>
                  <i className="mdi mdi-account mr-2 text-primary" />
                  <Trans>Change Company</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item nav-logout">
            <a className="nav-link" href="!#" onClick={handleLogoutClick}>
              <i className="mdi mdi-power" />
            </a>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
