import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './app/App';
import ReduxStore from './redux/reduxStore';
import * as serviceWorker from './serviceWorker';

import './i18n';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={ReduxStore.store}>
      <PersistGate loading={null} persistor={ReduxStore.persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);

serviceWorker.unregister();
