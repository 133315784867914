import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import useAuthUserData from '../hooks/useAuthUserData';

const ProtectedRoute = ({ children, ...rest }) => {
  const { isAuthenticated, selectedCompany } = useAuthUserData();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthenticated && selectedCompany !== null) {
          return children;
        }
        if (isAuthenticated && selectedCompany === null) {
          return (
            <Redirect
              to={{
                pathname: '/select-company',
                state: { from: location },
              }}
            />
          );
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );

        // eslint-disable-next-line no-nested-ternary
        // return isAuthenticated ? (
        //   selectedCompany !== null ? (
        //     children
        //   ) : (
        //     <Redirect
        //       to={{
        //         pathname: '/select-company',
        //         state: { from: location },
        //       }}
        //     />
        //   )
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: '/login',
        //       state: { from: location },
        //     }}
        //   />
        // );
      }}
    />
  );
};

export default ProtectedRoute;
