import React, { lazy, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Redirect, Route, Switch } from 'react-router-dom';

import Spinner from '../component/Loader/Spinner';
import ToastContainerWrapper from '../component/Toast/ToastContainerWrapper';
import Layout from '../container/shared/Layout';
import ProtectedRoute from '../hoc/ProtectedRoute';

import './App.scss';
import './App.css';

const Login = lazy(() => import('../container/Login/Login'));
const Dashboard = lazy(() => import('../container/dashboard/Dashboard'));
const Users = lazy(() => import('../container/users/Users'));
const AddUsers = lazy(() => import('../container/users/AddUsers'));
const UpdateUser = lazy(() => import('../container/users/UpdateUser'));
const Company = lazy(() => import('../container/company/Company'));
const AddCompany = lazy(() => import('../container/company/AddCompany'));
const UpdateCompany = lazy(() => import('../container/company/UpdateCompany'));
const SelectCompany = lazy(() => import('../container/Login/SelectCompany'));
const Transactions = lazy(() => import('../container/transactions/Transactions'));
const AccountStatement = lazy(() => import('../container/accountStatement/AccountStatement'));
const Items = lazy(() => import('../container/items/Items'));
const AddItem = lazy(() => import('../container/items/AddItem'));
const UpdateItem = lazy(() => import('../container/items/UpdateItem'));
const AddWholesaleBill = lazy(() => import('../container/bills/wholesale/AddWholesaleBill'));
const ListAllBills = lazy(() => import('../container/bills/ListAllBills'));
const UpdateBills = lazy(() => import('../container/bills/UpdateBills'));
const CashBook = lazy(() => import('../container/cashBook/CashBook'));
const AddRetailSaleBill = lazy(() => import('../container/bills/retail/AddRetailSaleBill'));
const PrintInvoice = lazy(() => import('../container/bills/printBill/PrintInvoiceContainer'));

function App() {
  const queryClient = new QueryClient();

  return (
    <Suspense fallback={<Spinner />}>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/select-company" component={SelectCompany} />
          <ProtectedRoute>
            <Layout>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/users/add" component={AddUsers} />
              <Route exact path="/users/update" component={UpdateUser} />
              <Route exact path="/company" component={Company} />
              <Route exact path="/company/add" component={AddCompany} />
              <Route exact path="/company/update" component={UpdateCompany} />
              <Route exact path="/transactions" component={Transactions} />
              <Route exact path="/account-statement" component={AccountStatement} />
              <Route exact path="/cash-book" component={CashBook} />
              <Route exact path="/items" component={Items} />
              <Route exact path="/items/add" component={AddItem} />
              <Route exact path="/items/update" component={UpdateItem} />
              <Route exact path="/bills/list" component={ListAllBills} />
              <Route exact path="/bills/update" component={UpdateBills} />
              <Route exact path="/bills/wholesale/create" component={AddWholesaleBill} />
              <Route exact path="/bills/retail/create" component={AddRetailSaleBill} />
              <Route exact path="/bills/print" component={PrintInvoice} />
            </Layout>
          </ProtectedRoute>
          <Redirect to="/login" />
        </Switch>
        <ToastContainerWrapper />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
