import { combineReducers } from 'redux';

import authReducer from './authReducer';
import languageReducer from './languageReducers';
import selectedCompanyReducers from './selectedCompanyReducers';

const rootReducer = combineReducers({
  auth: authReducer,
  app_language: languageReducer,
  selectedCompany: selectedCompanyReducers,
});

export default rootReducer;
