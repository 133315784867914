import { createSlice } from '@reduxjs/toolkit';

const authReducer = createSlice({
  name: 'authReducer',
  initialState: null,
  reducers: {
    LOGIN(state, action) {
      return action.payload;
    },
    LOGOUT(state, action) {
      return null;
    },
  },
});

export const { LOGIN, LOGOUT } = authReducer.actions;
export default authReducer.reducer;
