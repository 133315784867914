import { createSlice } from '@reduxjs/toolkit';

const selectedCompany = createSlice({
  name: 'selectedCompany',
  initialState: null,
  reducers: {
    changeSelectedCompany(state, action) {
      return action.payload;
    },
    clearSelectedCompany(state, action) {
      return null;
    },
  },
});

export const { changeSelectedCompany, clearSelectedCompany } = selectedCompany.actions;
export default selectedCompany.reducer;
