import React from 'react';

import loader from './loader.svg';

const Spinner = () => {
  return (
    <div>
      <div className="spinner-wrapper">
        <img
          src={loader}
          alt="Loading"
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
          }}
        />
      </div>
    </div>
  );
};

export default Spinner;
