import { createSlice } from '@reduxjs/toolkit';

const languageSlice = createSlice({
  name: 'app_language',
  initialState: 'en',
  reducers: {
    changeAppLanguage(state, action) {
      return action.payload;
    },
    clearAppLanguage(state, action) {
      return 'en';
    },
  },
});

export const { changeAppLanguage, clearAppLanguage } = languageSlice.actions;
export default languageSlice.reducer;
