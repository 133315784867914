import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import useAuthUserData from '../../hooks/useAuthUserData';
import { changeAppLanguage } from '../../redux/reducers/languageReducers';

const Footer = () => {
  const { i18n } = useTranslation();
  const { userData, appLanguage } = useAuthUserData();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeAppLanguage(userData.preffered_language.code || 'en'));
  }, [userData, dispatch]);

  useEffect(() => {
    i18n.changeLanguage(appLanguage);
  }, [appLanguage, i18n]);

  const handleOnChange = eve => {
    // i18n.changeLanguage(eve.target.value);
    dispatch(changeAppLanguage(eve.target.value));
  };

  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-sm-between py-2">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block" />
        <span className="mt-1 mt-sm-0 text-center">
          <div className="row">
            <div className="col-md-4">
              <span>Change Language</span>
            </div>
            <div className="col-md-8">
              <select className="form-control col-md-8" onChange={handleOnChange} value={appLanguage}>
                <option value="en">English</option>
                <option value="hi">Hindi</option>
                <option value="mr">Marathi</option>
              </select>
            </div>
          </div>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
