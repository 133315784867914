import { isEmpty, isNull } from 'lodash';
import { useSelector } from 'react-redux';

const useAuthUserData = () => {
  const authReduxData = useSelector(state => state.auth);
  const appLanguage = useSelector(state => state.app_language);
  const selectedCompany = useSelector(state => state.selectedCompany);
  let userData = null;
  let accessToken = null;
  if (authReduxData !== null) {
    userData = authReduxData.userData;
    accessToken = authReduxData.accessToken;
  }

  // eslint-disable-next-line operator-linebreak
  const isAuthenticated =
    userData !== null && !isEmpty(userData?.user_name) && isNull(userData?.deleted_at) && userData?.allow_login;

  return {
    userData,
    selectedCompany: selectedCompany ? selectedCompany[0] : null,
    accessToken,
    isAuthenticated,
    appLanguage,
  };
};

export default useAuthUserData;
